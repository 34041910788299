<template>
  <b-card>
    <b-table small :fields="fields" :items="items" responsive="sm">
      <!-- A virtual column -->
      <template #cell(index)="data">
        {{ data.index + 1 }}
      </template>

      <!-- A custom formatted column -->
      <template #cell(title)="data">
        {{ data.value && data.value }}
      </template>

      <!-- A custom formatted column -->
      <template #cell(Popularity)="data">
        <b-progress
          :value="data.value.value"
          :max="data.value.max"
          :variant="data.value.variant"
          striped
        />
      </template>

      <template #cell(status)="data">
        <b-badge pill :variant="data.value.variant">
          {{ data.value.status }}
        </b-badge>
      </template>
    </b-table></b-card
  >
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BTable,
  BProgress,
  BBadge,
  BFormTextarea,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import Cleave from "vue-cleave-component";

// eslint-disable-next-line import/no-extraneous-dependencies
import "cleave.js/dist/addons/cleave-phone.us";

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BFormTextarea,
    vSelect,
    flatPickr,
    Cleave,
    BTable,
    BProgress,
    BBadge,
  },
  directives: {
    Ripple,
  },
  props: ["quizes"],
  data() {
    return {
      items: [],
      fields: [
        "index",
        // A column that needs custom formatting
        { key: "title", label: "title" },
        // A regular column
        { key: "Popularity", label: "Status" },
        { key: "status", label: "Livel" },
        { key: "grade", label: "Marks" },
        // A virtual column made up from two fields
      ],
    };
  },
  methods: {},
  beforeCreate() {},
  created() {
    let tempArry = this.quizes.map((e) => e.quiz);
    for (let index = 0; index < this.quizes.length; index++) {
      if (tempArry[index] == null) {
        console.log(tempArry[index]);
      } else {
        tempArry[index].grade = Math.floor(this.quizes[index].grade);
        tempArry[index].Popularity = {
          value: tempArry[index].grade,
          variant: `${
            tempArry[index].grade > tempArry[index].grad / 2
              ? "success"
              : "danger"
          }`,
          max: tempArry[index].grad,
        };
        tempArry[index].status = {
          status: `${
            tempArry[index].grade > tempArry[index].grad / 2
              ? "success"
              : "fail"
          }`,
          variant: `${
            tempArry[index].grade > tempArry[index].grad / 2
              ? "success"
              : "danger"
          }`,
          max: tempArry[index].grad,
        };
      }

      this.items = tempArry.filter(function (el) {
        return el != null;
      });
      console.log(tempArry);
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
