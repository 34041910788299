<template>
  <b-card>
    <!-- media -->
    <b-media no-body>
      <b-media-aside>
        <b-link>
          <b-img ref="previewEl" rounded :src="user.profileImg" height="80" />
        </b-link>
        <!--/ avatar -->
      </b-media-aside>

      <b-media-body class="mt-75 ml-75">
        <!-- <b-card-text>Allowed JPG, GIF or PNG. Max size of 800kB</b-card-text> -->
      </b-media-body>
    </b-media>
    <!--/ media -->

    <!-- form -->
    <form ref="form" @submit.stop.prevent="handleSubmit">
      <validation-observer ref="userlogindInfoRules" tag="form">
        <b-row>
          <b-col cols="12" class="mb-2">
            <h5 class="mb-0">user Details</h5>
            <small class="text-muted"> Enter the user Details. </small>
          </b-col>
          <b-col md="12">
            <b-form-group
              class="auth-register-form mt-2"
              label="user profile image"
              label-for="CorseCoverImage"
            >
              <validation-provider #default="{ errors }" name="CorseCoverImage">
                <b-form-file
                  :state="Boolean(user.profileImg)"
                  id="CorseCoverImage"
                  accept="image/*"
                  v-model="user.profileImg"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              class="auth-register-form w-100"
              label="name"
              label-for="name"
            >
              <validation-provider
                #default="{ errors }"
                name="name"
                rules="required|min:3"
              >
                <b-form-input
                  id="name"
                  v-model="user.name"
                  :state="errors.length > 0 ? false : null"
                  placeholder="user name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Email" label-for="register-email">
              <validation-provider
                #default="{ errors }"
                name="Email"
                vid="email"
                rules="required|email"
              >
                <b-form-input
                  id="register-email"
                  v-model="user.email"
                  name="register-email"
                  :state="errors.length > 0 ? false : null"
                  placeholder="john@example.com"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="phone" label-for="register-email">
              <validation-provider
                #default="{ errors }"
                name="phone"
                vid="phone"
                rules="required|numeric"
              >
                <b-form-input
                  id="register-phone"
                  v-model="user.phone"
                  name="register-phone"
                  :state="errors.length > 0 ? false : null"
                  placeholder="01000000000"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="birthDate" label-for="birthDate">
              <validation-provider
                #default="{ errors }"
                name="birthDate"
                vid="birthDate"
                rules="required"
              >
                <b-form-datepicker
                  id="register-birthDate"
                  v-model="user.birthDate"
                  name="register-birthDate"
                  :state="errors.length > 0 ? false : null"
                ></b-form-datepicker>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <validation-provider
              #default="{ errors }"
              name="Country"
              rules="required"
            >
              <b-form-group label="Country" label-for="country">
                <v-select
                  v-model="user.nationality"
                  :options="countries"
                  :clearable="false"
                  input-id="country"
                />
                <small class="text-danger">{{ errors[0] }}</small>

                <!-- <b-form-invalid-feedback>
                    {{ errors[0] }}
                  </b-form-invalid-feedback> -->
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- password -->
        </b-row>
      </validation-observer>
      <b-row variant="info">
        <b-col md="3">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class=""
            variant="outline-secondary"
            block
            @click="hideModal"
          >
            No
          </b-button>
        </b-col>
        <b-col md="6">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class=""
            variant="outline-info"
            block
            @click="toggleModal"
          >
            <feather-icon icon="Edit2Icon" class="mr-50" />
            <span class="mr-50 text-info">update</span>
          </b-button>
        </b-col>
      </b-row>
    </form>
  </b-card>
</template>

<script>
import {
  BFormFile,
  BForm,
  BFormGroup,
  BAlert,
  BCard,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
  BButton,
  BFormInput,
  BModal,
  BListGroup,
  BListGroupItem,
  BRow,
  BCol,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";

import Ripple from "vue-ripple-directive";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref } from "@vue/composition-api";
import imagesApi from "@/apiServices/imagesApi";
import vSelect from "vue-select";
import countries from "@/auth/countries";
import { required, email, max_value, numeric } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import usersApi from "@/apiServices/userApi";

export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormGroup,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BFormInput,
    BListGroup,
    BListGroupItem,
    BModal,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    user: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      required,
      email,
      max_value,
      numeric,
      countries,
      user: {},
      profileFile: null,
    };
  },
  methods: {
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      if (this.user.profileImg instanceof File) {
        const imageFormData = new FormData();
        imageFormData.append("image", this.user.profileImg);
        imagesApi.uploadImage(imageFormData).then((response) => {
          console.log(response);
          this.user.profileImg = response;
          this.updateuser();
        });
      } else {
        // delete this.user.email;
        // delete this.user.profileImg;
        this.updateuser();
      }
    },
    updateuser() {
      this.$refs.userlogindInfoRules.validate().then((success) => {
        if (success) {
          usersApi.updateMe(this.user).then((response) => {
            console.log(response.data);
            if (response.data) {
              this.user = response.data?.data;
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "your general data have been updated",
                  icon: "EditIcon",
                  variant: "success",
                },
              });
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.message,
                  icon: "EditIcon",
                  variant: "danger",
                },
              });
            }
          });
        }
      });
    },
  },
  created() {},
  setup() {
    const refInputEl = ref(null);
    const previewEl = ref(null);

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl);
    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    };
  },
};
</script>
