<template>
  <b-card>
    <b-row>
      <b-col md="6" lg="4" v-for="course in courses" :key="course._id">
        <courseCard :course="course" />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BFormFile,
  BForm,
  BFormGroup,
  BAlert,
  BCard,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
  BButton,
  BFormInput,
  BModal,
  BListGroup,
  BListGroupItem,
  BRow,
  BCol,
  BFormInvalidFeedback,
  BDropdown,
  BDropdownItem,
  BFormRadioGroup,
  BFormRadio,
  BInputGroup,
  BInputGroupAppend,
  BPagination,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";

import Ripple from "vue-ripple-directive";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref } from "@vue/composition-api";
import imagesApi from "@/apiServices/imagesApi";
import vSelect from "vue-select";
import countries from "@/auth/countries";
import { required, email, max_value, numeric } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import courseCard from "@/views/courses pages/components/course card.vue";
import usersApi from "@/apiServices/userApi";

export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormGroup,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BFormInput,
    BListGroup,
    BListGroupItem,
    BModal,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BDropdown,
    BDropdownItem,
    BFormRadioGroup,
    BFormRadio,
    BInputGroup,
    BInputGroupAppend,
    BPagination,
    courseCard,
  },
  directives: {
    Ripple,
  },
  props: {
    user: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      required,
      email,
      max_value,
      numeric,
      countries,
      profileFile: null,
      courses: null,
    };
  },
  methods: {
    getMyCourses() {
      usersApi.getMyCourses().then((response) => {
        if (response.data) {
          console.log(response.data);
          this.courses = response.data?.data;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "this is All of your Course",
              icon: "EditIcon",
              variant: "success",
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      });
    },
  },
  created() {
    this.getMyCourses();
  },
};
</script>
<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}
</style>
