<template>
  <b-card>
    <b-tabs
      vertical
      content-class="col-12 col-md-9 mt-1 mt-md-0"
      pills
      nav-wrapper-class="col-md-3 col-12"
      nav-class="nav-left"
    >
      <!-- Courses tab -->
      <b-tab active v-if="user">
        <!-- title -->
        <template #title>
          <feather-icon icon="UserIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Courses</span>
        </template>

        <userCourses v-if="user" :user="user" />
      </b-tab>
      <b-tab>
        <!-- title -->
        <template #title>
          <feather-icon icon="InfoIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Quizes</span>
        </template>
        <account-setting-information v-if="user.quizes" :quizes="user.quizes" />
      </b-tab>
      <b-tab>
        <!-- title -->
        <template #title>
          <feather-icon icon="UserIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">General</span>
        </template>

        <account-setting-general v-if="user" :user="user" />
      </b-tab>
      <!--/ general tab -->

      <!-- change password tab -->
      <b-tab>
        <!-- title -->
        <template #title>
          <feather-icon icon="LockIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Change Password</span>
        </template>

        <account-setting-password v-if="user" :user="user" />
      </b-tab>
      <!--/ change password tab -->

      <!-- info -->

      <!-- social links -->
      <!-- <b-tab>
        <template #title>
          <feather-icon icon="LinkIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Social</span>
        </template>

        <account-setting-social
          v-if="options.social"
          :social-data="options.social"
        />
      </b-tab> -->

      <!-- notification -->
      <!-- <b-tab>
        <template #title>
          <feather-icon icon="BellIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Notifications</span>
        </template>

        <account-setting-notification
          v-if="options.notification"
          :notification-data="options.notification"
        />
      </b-tab> -->
    </b-tabs>
  </b-card>
</template>

<script>
import { BTabs, BTab } from "bootstrap-vue";
import AccountSettingGeneral from "./AccountSettingGeneral.vue";
import AccountSettingPassword from "./AccountSettingPassword.vue";
import AccountSettingInformation from "./AccountSettingInformation.vue";
import AccountSettingSocial from "./AccountSettingSocial.vue";
import AccountSettingNotification from "./AccountSettingNotification.vue";
import userCourses from "./userCourses.vue";
import usersApi from "@/apiServices/userApi";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BTabs,
    BTab,
    AccountSettingGeneral,
    AccountSettingPassword,
    AccountSettingInformation,
    AccountSettingSocial,
    AccountSettingNotification,
    userCourses,
  },
  data() {
    return {
      user: null,
      options: {
        general: {
          avatar: require("@/assets/images/portrait/small/avatar-s-11.jpg"),
          username: "johndoe",
          fullName: "John Doe",
          email: "granger007@hogward.com",
          company: "Crystal Technologies",
        },
        info: {
          bio: "",
          dob: null,
          country: "USA",
          website: "",
          phone: 6562542568,
        },
        social: {
          socialLinks: {
            twitter: "https://www.twitter.com",
            facebook: "",
            google: "",
            linkedIn: "https://www.linkedin.com",
            instagram: "",
            quora: "",
          },
          connections: {
            twitter: {
              profileImg: require("@/assets/images/avatars/11-small.png"),
              id: "johndoe",
            },
            google: {
              profileImg: require("@/assets/images/avatars/3-small.png"),
              id: "luraweber",
            },
            facebook: {},
            github: {},
          },
        },
        notification: {
          commentOnArticle: true,
          AnswerOnForm: true,
          followMe: false,
          newAnnouncements: true,
          productUpdates: true,
          blogDigest: false,
        },
      },
    };
  },
  methods: {
    getMe() {
      usersApi.getMe().then((response) => {
        if (response.data) {
          console.log(response.data);
          this.user = response.data?.data;
          localStorage.setItem("user", JSON.stringify(this.user));

          // this.previous = response.data?.data;
          // console.log(
          //   JSON.stringify(this.previous) === JSON.stringify(this.course)
          // );
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.user.name,
              icon: "EditIcon",
              variant: "success",
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      });
    },
  },
  created() {
    this.getMe();
  },
  mounted() {
    let user = localStorage.getItem("user");

    if (user == null) {
      this.getMe();
    }
  },
};
</script>
