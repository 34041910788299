import { render, staticRenderFns } from "./userCourses.vue?vue&type=template&id=32aec022&scoped=true&"
import script from "./userCourses.vue?vue&type=script&lang=js&"
export * from "./userCourses.vue?vue&type=script&lang=js&"
import style0 from "./userCourses.vue?vue&type=style&index=0&id=32aec022&prod&lang=scss&"
import style1 from "./userCourses.vue?vue&type=style&index=1&id=32aec022&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32aec022",
  null
  
)

export default component.exports